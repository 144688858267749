import React from "react";

import Nav from "./Nav";

function Layout({ children }: any) {
  return (
    <div className="flex flex-col min-h-screen pt-16">
      <Nav />
      <main className="mx-auto w-full flex-grow">{children}</main>

      <footer className="bg-gray-900">
        <nav className="flex justify-between max-w-4xl px-4 py-4 md:p-8 mx-auto text-xs md:text-sm">
          <p className="font-bold text-white no-underline antialiased">
            &copy; {new Date().getFullYear()} BILGRI & MANSKE, SC.
          </p>
          <p className="text-white antialiased text-right">
            Created by{" "}
            <a
              className="font-bold no-underline border-b border-primary antialiased"
              href="https://wemaketechsimple.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              InterQuest
            </a>
          </p>
        </nav>
      </footer>
    </div>
  );
}

export default Layout;
